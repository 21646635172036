<template>
  <div class="container d-flex justify-content-center mb-4">
    <div class="circle-border"></div>
    <div class="circle">
      <div class="error"></div>
    </div>
  </div>
</template>

<style scoped>
.circle,
.circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  border: 3px solid rgb(255, 59, 59);
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 4px;
  background: rgb(255, 59, 59);
  position: absolute;
}

.error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}

.error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}
</style>
