<template>
  <div class="lp_container">
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto glass_a">
        <form @submit.prevent="submitForm">
          <span>Lupa Password</span>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button type="submit" class="btn btn_gradient">
            <span v-if="!loading" class="m-0" style="font-size: 1rem"
              >Kirim</span
            >
            <span
              v-else
              class="spinner-border spinner-border-sm mx-auto my-0"
              aria-hidden="true"
            ></span>
          </button>
        </form>

        <!-- error box -->
        <ErrorAlert :show="isError" :message="errorMessage" />

        <h2>
          Apakah Anda ingat kata sandi Anda?
          <router-link to="/auth/login" class="primary-link">
            Klik di sini
          </router-link>
        </h2>
      </div>
    </div>

    <Footer />

    <teleport to="body">
      <ModalSuccess :show="showModalRes" @close="showModalRes = false">
        <template #body>
          <SuccessAnimation />
          <p style="text-align: center">
            {{ resMsg }}
          </p>
        </template>
      </ModalSuccess>
    </teleport>
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import ModalSuccess from "@/components/modals/ModalSuccess.vue";
import SuccessAnimation from "@/components/animations/SuccessAnimation.vue";
import ErrorAlert from "@/components/global/ErrorAlert.vue";

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
    ModalSuccess,
    SuccessAnimation,
    ErrorAlert,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      email: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
      isError: false,
      errorMessage: null,
      showModalRes: false,
      resMsg: null,
      loading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;
      this.loading = true;

      if (self.recaptchaToken != "") {
        var res = await thestore.postAuthForgotPassword(
          self.email,
          self.recaptchaToken
        );

        if (res.success == true) {
          self.showModalRes = true;
          self.resMsg = res.msg;
          // alert(res.msg);
          setTimeout(() => {
            self.$router.push("/");
          }, 10000);
        } else {
          self.isError = true;
          self.errorMessage = res.msg;
          // alert(res.msg);
          if (res.msg == "Wrong captcha") {
            setTimeout(() => {
              self.$router.go();
            }, 2000);
          }
        }
      }

      this.loading = false;
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },
  },
};
</script>

<style scoped>
.lp_container {
  position: relative;
  height: 100%;
  background-image: url("/images/bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.form-control {
  color: black !important;
}

.glass_a {
  /* From https://css.glass */
  background: linear-gradient(
    114deg,
    rgba(121, 67, 239, 0.18) 0%,
    rgba(108, 36, 161, 0.29) 36%,
    rgba(33, 93, 154, 0.28) 72%,
    rgba(146, 105, 197, 0.43) 100%
  );

  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px 0;
}

.btn_gradient {
  background-color: #708cc7 !important;
  color: #fff;
}

.btn_gradient:hover {
  background-color: #5572b1 !important;
}

.primary-link {
  color: #708cc7;
}

@media only screen and (min-width: 768px) {
  .glass_a {
    padding: 30px;
  }
}
</style>
