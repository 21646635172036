<!-- ini adalah deposit untuk EVM: (ETH, BNB, Arbitrum dan token-token dibawha EVM) -->
<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <a class="nav-link deposit-head active text-white" href="#">
                  Deposit
                </a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link withdraw-head text-white"
                  :to="`/settings/wallets/${currency}/withdraw`"
                >
                  Withdraw
                </router-link>
              </li>
            </ul>

            <!-- 
            Bagian address
            .
            Address pada EVM itu sama semua. Contoh EVM adalah ETH, BNB dan token seperti Shiba dan lain-lain 
            Jadi disini kita harus cek ke api apakah tipe address dari currency yang sedang kita minta
            .
            Jika dari API dengan address_view == EvmAddress, maka harus dimunculkan component EvmAddress 
            -->
            <EvmAddress
              v-if="ccurrencyObj.ccurrency_config.address_view == 'EvmAddress'"
              :currency="currency"
            />

            <!-- ================================= DEPOSIT INFO ================================= -->
            <!-- 
            deposit_info_view JIKA DIA ADALAH TOKEN
            .
            Pada EVM, token akan mempunyai address. Jadi disini kita akan menampilkan address dari token tersebut
            -->
            <EvmTokenInfo
              v-if="
                ccurrencyObj.ccurrency_config.deposit_info_view ==
                'EvmTokenInfo'
              "
              :activeCurrencyObj="ccurrencyObj"
            />

            <!-- 
            deposit_info_view JIKA DIA ADALAH ETH ATAU COIN
            .
            Pada EVM, coin adalah native currency dari jaringan tersebut.
            -->
            <EvmEthInfo
              v-if="
                ccurrencyObj.ccurrency_config.deposit_info_view == 'EvmEthInfo'
              "
              :activeCurrencyObj="ccurrencyObj"
            />
            <!-- ================================= DEPOSIT INFO ================================= -->

            <!-- ================================= MANUAL DEPOSIT ================================= -->
            <!-- bagian manual deposit -->
            <EvmCoinBNBDepositManual
              v-if="
                ccurrencyObj.ccurrency_config.deposit_manual_view ==
                'EvmCoinBNBDepositManual'
              "
              :activeCurrencyObj="ccurrencyObj"
              :currency="currency"
            />

            <EvmCoinETHDepositManual
              v-if="
                ccurrencyObj.ccurrency_config.deposit_manual_view ==
                'EvmCoinETHDepositManual'
              "
              :activeCurrencyObj="ccurrencyObj"
              :currency="currency"
            />

            <EvmTokenDepositManual
              v-if="
                ccurrencyObj.ccurrency_config.deposit_manual_view ==
                'EvmTokenDepositManual'
              "
              :activeCurrencyObj="ccurrencyObj"
              :currency="currency"
            />
            <!-- ================================= MANUAL DEPOSIT ================================= -->

            <!-- list deposit -->
            <DepositList :currency="currency" />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";
import EvmAddress from "@/components/Settings/Wallets/Deposit/Address/EvmAddress.vue";
import EvmTokenInfo from "@/components/Settings/Wallets/Deposit/Info/EvmTokenInfo.vue";
import EvmEthInfo from "@/components/Settings/Wallets/Deposit/Info/EvmEthInfo.vue";
import EvmCoinBNBDepositManual from "@/components/Settings/Wallets/Deposit/Manual/EvmCoinBNBDepositManual.vue";
import EvmCoinETHDepositManual from "@/components/Settings/Wallets/Deposit/Manual/EvmCoinETHDepositManual.vue";
import EvmTokenDepositManual from "@/components/Settings/Wallets/Deposit/Manual/EvmTokenDepositManual.vue";
import DepositList from "@/components/Settings/Wallets/Deposit/List.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
    DepositList,
    EvmAddress,
    EvmTokenInfo,
    EvmEthInfo,
    EvmCoinBNBDepositManual,
    EvmCoinETHDepositManual,
    EvmTokenDepositManual,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: this.$route.params.currency,
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
      },

      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },
  },
};
</script>
