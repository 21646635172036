<template>
  <div class="hero_container">
    <div class="hero_content">
      <div class="hero_head">
        <h2>Pengalaman Kripto untuk Semua</h2>
        <p>
          Hadirkan aset kripto kepada masyarakat, CoinX mendukung pengalaman
          bagi pengguna tingkat lanjut dan konsumen baru.
        </p>
      </div>

      <div class="hero_btn">
        <router-link class="btn_a" to="/markets/1/BTC_IDR"
          >BELI KRIPTO SEKARANG</router-link
        >
        <router-link class="btn_b" to="/markets">LIHAT PASAR</router-link>
      </div>

      <div class="hero_licenced">
        <h2>TERDAFTAR DAN DILISENSIKAN OLEH :</h2>
        <div class="hero_logos">
          <img src="/images/km-perdagangan-logo.png" alt="mendagri" />
          <img src="/images/tsi-logo.jpeg" alt="tsi" />
          <img src="/images/kominfo-logo.png" alt="kominfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hero_container {
  background-image: url("/images/hero-bg-03.png");
  background-size: cover;
  height: 100vh;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.hero_head {
  max-width: 944px;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.hero_head h2 {
  font-size: 64px;
  text-align: center;
}
.hero_head p {
  font-size: 20px;
  max-width: 589px;
  text-align: center;
}
.hero_btn {
  display: flex;
  gap: 20px;
}
.btn_a {
  padding: 16px 33px;
  border-radius: 36px;
  border: 1px solid #131926;
  background: var(
    --BUTTON,
    linear-gradient(260deg, #0047ff -13.37%, #00bcd4 109.53%)
  );
  color: #fff;
}
.btn_b {
  padding: 16px 33px;
  border-radius: 36px;
  border: 1px solid #fff;
  background: #131926;
  color: #fff;
}
.hero_licenced {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}
.hero_licenced h2 {
  font-size: 15px;
  color: #618adc;
}
.hero_logos {
  display: flex;
  align-items: center;
  gap: 30px;
}
.hero_logos img {
  height: 44px;
  width: auto;
}

@media only screen and (max-width: 866px) {
  .hero_head {
    padding: 0 10px;
  }
  .hero_head h2 {
    font-size: 30px;
  }
  .hero_head p {
    font-size: 14px;
  }
  .btn_a,
  .btn_b {
    padding: 8px 20px;
    font-size: 14px;
  }
}
</style>
