<template>
  <div class="cs_wrapper">
    <div class="cs_container">
      <Carousel :items-to-show="column" :wrap-around="true">
        <Slide v-for="slide in 3" :key="slide">
          <div class="carousel__item">
            <card-carausel :num="slide" />
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import CardCarausel from "./CardCarausel.vue";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "WrapAround",
  components: {
    Carousel,
    Slide,
    Navigation,
    CardCarausel,
  },
  data() {
    return {
      column: 2.5,
    };
  },
  mounted() {
    this.screenDimention();
  },
  methods: {
    screenDimention() {
      const widthS = window.innerWidth;
      if (widthS <= 780) {
        this.column = 1.5;
      }
      if (widthS <= 500) {
        this.column = 1;
      }
    },
  },
});
</script>

<style scoped>
.cs_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 50px 0;
}
.cs_container {
  max-width: 95%;
}
.carousel__item {
  margin-right: 20px;
}

@media only screen and (max-width: 500px) {
  .cs_wrapper {
    justify-content: center;
  }
  .cs_container {
    width: 100%;
  }
}
</style>
