<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <h3 class="text-white">Pilih Kewarganegaraan Anda</h3>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col text-right">
            <button class="btn btn-gradient" @click="submitFormIndonesia">
              Indonesia
            </button>
          </div>
          <div class="col text-left">
            <button class="btn btn-gradient" @click="submitFormNonIndonesia">
              Bukan Indonesia
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      msg: "",
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
      namaAppB: process.env.VUE_APP_NAMA_APP_B,
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    // checking KYC step
    await self.kycChecing();
  },

  methods: {
    async kycChecing() {
      var self = this;

      var res = await thestore.postKycCheckingStep(self.token);
      // console.log("res", res);
      if (res.kyc_step != 0) {
        if (res.kyc_type == "Indonesia") {
          if (res.kyc_step == 1) {
            self.$router.push("/kyc/id/dua");
          } else if (res.kyc_step == 2) {
            self.$router.push("/kyc/id/tiga");
          } else if (res.kyc_step == 3) {
            self.$router.push("/kyc/id/tiga");
          }
        } else {
          if (res.kyc_step == 1) {
            self.$router.push("/kyc/global/dua");
          } else if (res.kyc_step == 2) {
            self.$router.push("/kyc/global/tiga");
          } else if (res.kyc_step == 3) {
            self.$router.push("/kyc/global/tiga");
          }
        }
      }
    },

    async submitFormIndonesia() {
      var self = this;

      var res = await thestore.postKycNationality(self.token, "id");
      if (res.success == true) {
        self.$router.push("/kyc/id/satu");
      } else {
        alert(this.msg);
      }
    },

    async submitFormNonIndonesia() {
      var self = this;

      var res = await thestore.postKycNationality(self.token, "global");
      if (res.success == true) {
        self.$router.push("/kyc/global/satu");
      } else {
        alert(this.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
