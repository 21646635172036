<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <a class="nav-link deposit-head active" href="#"> Deposit </a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link withdraw-head text-white"
                  :to="`/settings/wallets/btc_withdraw`"
                >
                  Withdraw
                </router-link>
              </li>
            </ul>

            <div
              class="row wallet-address"
              v-if="bitcoinAddress != '' && bitcoinAddress != null"
            >
              <div class="row">
                <div class="col-md-8">
                  <p class="text-light">
                    Deposits to this address are unlimited. Note that you may
                    not be able to withdraw all of your funds at once if you
                    deposit more than your daily withdrawal limit.
                  </p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      :value="`${bitcoinAddress}`"
                      :id="`input_address_${currency}`"
                    />
                    <!-- does not work -->
                    <div class="input-group-prepend">
                      <button
                        class="btn btn-gradient"
                        @click="copyToClipBoard(bitcoinAddress)"
                      >
                        COPY
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <qrcode-vue :value="bitcoinAddress" :size="200" level="H" />
                </div>
              </div>
            </div>

            <button
              class="btn btn-outline-yellow"
              v-else
              @click="requestAnAddress"
            >
              Minta Address
            </button>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Daftar Deposit</h5>
                <div class="wallet-history">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-left">No.</th>
                        <th class="text-left">Waktu</th>
                        <th class="text-left">TXID</th>
                        <th class="text-right">Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in deposits" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          {{
                            moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </td>
                        <td>{{ item.txid }}</td>
                        <td class="text-right">
                          {{ parseFloat(item.amount).numberFormat(8) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";
import QrcodeVue from "qrcode.vue";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import { useToast } from "vue-toastification";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";

const toast = useToast();

export default {
  components: {
    QrcodeVue,
    TopNav,
    Footer,
    Menu,
    HeadBalance,
  },
  data() {
    return {
      themain,
      moment,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: "BTC",
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
      },
      bitcoinAddress: "",

      deposits: [],

      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;

    // Dapatkan wallet address dari API
    await self.getBitcoinWalletAddress();

    await self.getCcurrencyData();
    await self.getUserData();

    // ambil data deposit
    await self.getDeposits();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },

    async getDeposits() {
      var self = this;
      var filter_rules = [];
      var depoRes = await thestore.getWalletsBtcDeposits(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.deposits = depoRes.datas;
    },

    async getBitcoinWalletAddress() {
      var self = this;
      var addressObj = await thestore.getBitcoinWalletAddress(self.token);
      if (addressObj.success == true) {
        self.bitcoinAddress = addressObj.data.wallet_address;
      }
    },

    async requestAnAddress() {
      var self = this;

      var res = await thestore.postRequestBitcoinAddress(self.token);
      if (res.success == true) {
        self.bitcoinAddress = res.data.wallet_address;
      } else {
        alert(res.msg);
      }
    },

    copyToClipBoard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Berhasil disalin ke papan klip!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
  },
};
</script>
