<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <router-link
                  class="nav-link deposit-head text-white"
                  :to="`/settings/wallets/btc_deposit`"
                >
                  Deposit
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link withdraw-head active"> Withdraw </a>
              </li>
            </ul>

            <div class="card">
              <div class="card-body">
                <!-- <div class="alert alert-warning">
                  <p class="text-dark">
                    COMPANY_NAME menerapkan prinsip Travel Rules dengan menggunakan form
                    yang wajib diisi oleh Pelanggan pada saat akan melakukan penarikan Aset Kripto.
                    Untuk melakukan withdraw aset kripto maka pelanggan wajib memilih jaringan yang
                    diinginkan. Untuk memenuhi ketentuan Travel Rules sesuai dengan ketentuan, maka
                    untuk penarikan dibawah USD1000 nasabah wajib memasukan alamat wallet tujuan dan
                    nama pemilik wallet dan penarikan diatas USD 1000 maka nasabah wajib memasukkan
                    informasi tambahan alamat tinggal penerima.
                  </p>
                </div> -->

                <form
                  @submit.prevent="
                    user.two_fa_is_active
                      ? submitWithdrawal()
                      : toggleModalOtp()
                  "
                >
                  <div class="mt-4">
                    <div v-if="travel_rules_element" class="mb-4">
                      <div class="row mt-1">
                        <div class="col-md-4 text-right">&nbsp;</div>
                        <div class="col-md-8">
                          <h4 class="text-dark">Travel Rules</h4>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <label class="col-md-4 text-right"
                          >Nama Penerima :</label
                        >
                        <div class="col-md-8">
                          <input
                            type="text"
                            v-model="wd.to_name"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label class="col-md-4 text-right"
                          >Alamat Penerima :</label
                        >
                        <div class="col-md-8">
                          <input
                            type="text"
                            v-model="wd.to_home_address"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-1">
                      <label class="col-md-4 text-right"
                        >Wallet Address :</label
                      >
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="wd.withdraw_to"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="row mt-1">
                      <label class="col-md-4 text-right">Jumlah :</label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="wd.amount"
                          class="form-control"
                          @keyup="calcAfterFee"
                          @keydown="calcAfterFee"
                        />
                      </div>
                    </div>

                    <div class="row mt-1">
                      <label class="col-md-4 text-right">Biaya :</label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="wd.fee"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="row mt-1">
                      <label class="col-md-4 text-right">Terima Bersih :</label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="after_fee"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <!-- dengan OTP jika 2FA tidak aktif -->
                    <div class="row mt-1" v-if="user.two_fa_is_active == true">
                      <label class="col-md-4 text-right">Google Secret :</label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="wd.google_secret"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-4">&nbsp;</div>
                      <div class="col-md-8">
                        <button type="submit" class="btn btn-light">
                          Withdraw
                        </button>

                        <ModalOtp
                          :show="isModalOtpShow"
                          @getOtpEvent="getOtp"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!-- card withdraws -->
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Daftar Withdraw</h5>
                <div class="wallet-history">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-left">No.</th>
                        <th class="text-left">Waktu</th>
                        <th class="text-left">Address</th>
                        <th class="text-left">TXID</th>
                        <th class="text-right">Jumlah</th>
                        <th class="text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in withdraws" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          {{
                            moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </td>
                        <td>{{ item.to_address }}</td>
                        <td>{{ item.txid }}</td>
                        <td class="text-right">
                          {{ parseFloat(item.amount).numberFormat(8) }}
                        </td>
                        <td class="text-left">{{ item.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";
// import BtnRequestOtp from "@/components/global/BtnRequestOtp.vue";
import ModalOtp from "@/components/modals/ModalOtp.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
    // BtnRequestOtp,
    ModalOtp,
  },
  data() {
    return {
      themain,
      moment,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: "BTC",
      ccurrencyObj: {},
      isModalOtpShow: false,

      user: {},

      bank: {},

      after_fee: 0,
      wd: {
        to_name: "",
        to_home_address: "",
        amount: 0,
        otp: "",
        google_secret: "",
        withdraw_to: "",
        fee: 0,
      },

      withdraws: [],

      travel_rules_element: false,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected -bal");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        // jika ada yang mengarah kepada kita, maka kita akan ubah data
        if (parseInt(self.usid) == el.u) {
          // disini kita sudah dapat 1 data saja
          // seperti: {"u":3,"BTC":"9.982423893170436","IDR":"999574426.8375854"}
          var balObjSocket = el;

          // iterasi semua balances
          self.balances.forEach(function (elbal, indexBalances) {
            // perulangan semua map dari balanceObjSocket
            // iterasi dari key: ['u', 'BTC', 'IDR']
            Object.keys(balObjSocket).forEach(function (balSocketKey) {
              if (elbal.ccurrency_symbol == balSocketKey) {
                self.balances[indexBalances].saldo_value =
                  balObjSocket[balSocketKey];
              }
            });
          });
        }
      });
    },
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();
    await self.getWithdraws();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];

      self.wd["fee"] = res.datas[0].ccurrency_withdraw_fee;
    },

    calcAfterFee() {
      var self = this;
      self.after_fee = parseFloat(self.wd.amount) - parseFloat(self.wd.fee);

      if (parseFloat(self.wd.amount) >= self.ccurrencyObj.travel_rules_limit) {
        self.travel_rules_element = true;
      } else {
        self.travel_rules_element = false;
      }
    },

    async submitWithdrawal() {
      var self = this;

      if (parseFloat(self.wd.amount) >= self.ccurrencyObj.travel_rules_limit) {
        if (self.wd.to_name == "" || self.wd.to_home_address == "") {
          alert("Anda wajib mengisi nama dan alamat penerima");
          return;
        }
      }

      var res = await thestore.postWalletsBtcWithdraw({
        token: self.token,
        withdraw_to: self.wd.withdraw_to,
        to_name: self.wd.to_name,
        to_home_address: self.wd.to_home_address,
        amount: self.wd.amount,
        otp: self.wd.otp,
        google_secret: self.wd.google_secret,
      });

      console.log("res", res);

      if (res.success == true) {
        alert("Withdraw sedang dalam proses.");
        self.wd.withdraw_to = "";
        self.wd.otp = "";
        self.wd.google_secret = "";
        self.wd.amount = 0;
        self.wd.to_name = "";
        self.wd.to_home_address = "";
      } else {
        alert(res.msg);
      }

      self.$router.go();
    },

    async getWithdraws() {
      var self = this;
      var filter_rules = [];
      var res = await thestore.getWalletsBtcWithdraws(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.withdraws = res.datas;
    },

    toggleModalOtp() {
      // validasi untuk setiap kolom form harus diisi jika tidak fungsi akan break
      let isValid = this.formValidate();

      if (!isValid) {
        return;
      }

      // toggle untuk modal OTP jika 2FA disable
      if (!this.user.two_fa_is_active)
        this.isModalOtpShow = !this.isModalOtpShow;
    },

    getOtp(code) {
      this.wd.otp = code;
      this.submitWithdrawal();
    },

    formValidate() {
      if (
        !this.wd.withdraw_to ||
        !this.wd.to_name ||
        !this.wd.to_home_address ||
        !this.wd.amount
      ) {
        toast.warning("Kolom tidak boleh kosong");
        return false;
      }

      return true;
    },
  },
};
</script>
